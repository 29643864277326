import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Container } from "react-bootstrap"
import Layout from "@components/Layout/Layout"
import Seo from "../components/seo"
import Breadcrumbs from "@components/Breadcrumbs/Breadcrumbs"
import CTALink from "@components/elements/CTALink"
import _ from "lodash"
const Sitemap = () => {
  const data = useStaticQuery(graphql`
  query {
    strapiGlobalMenu {
      header_links {
        id
        # content_cta {
        #   ...contentCtaFragment
        # }
        main_menu {
          ...ctaFragment
        }
        sub_menu_cta {
          ...ctaFragment
        }
      }
    }
  }
`)

const { header_links } = data.strapiGlobalMenu


  const breadcrumbURLs = [
    {
      url: "",
      label: "Sitemap",
    },
  ]
  return (
    <Layout
      pageData={{
        page_class: "sitemap-page-wrap",
        layout: "sitemap",
      }}
    >
       <Breadcrumbs staticURLs={breadcrumbURLs} />
      <Container className="sitemap-page">
        <h1>Sitemap</h1>
        <div className="sitemap-block">
          <ul className="list">
        {!_.isEmpty(header_links) &&
            header_links?.slice(0, 7).map((item) => {
              const { main_menu, sub_menu_cta, id, content_cta } = item
              return (
                <li className="parent" key={id}>
                  <CTALink
                    key={main_menu.id}
                    cta={main_menu}
                    className="main-menu"
                    alsoLabel
                  />
                  {!_.isEmpty(sub_menu_cta) && (
                        <div>
                          <div>
                              {!_.isEmpty(sub_menu_cta) && (
                                <ul className="sub">
                          {sub_menu_cta.map((cta) => {
                          // const { title, ctas } = subItem
                          return (
                           
                                  cta.cta_label === "See All Properties" ||
                                  cta.cta_label === "All Developers" || 
                                  cta.cta_label === "All Areas in Dubai" ||
                                  cta.cta_label === "See all properties for sale"
                                     || cta.cta_label === "See All New Projects"
                                     || cta.cta_label === "See All Areas in Dubai"
                                      || cta.cta_label === "See all properties for rent" ? null :
                                    <li className="child">
                                    <CTALink
                                      key={cta.id}
                                      cta={cta}
                                      className="sub-menu-link"
                                    /></li>
                                  
                              
                          )
                        })}
                          </ul>
                              )}
                            </div>
                       
                        </div>
                   
                  )}
                 
                </li>
              )
            })}
         {!_.isEmpty(header_links) &&
            header_links?.slice(7, 8)[0]?.sub_menu_cta?.map((item) => {
              // const { cta } = item
              return (
                <li className="parent" key={item?.id}>
                  <CTALink
                    key={item.id}
                    cta={item}
                    className="main-menu"
                    alsoLabel
                  />
                   
                 
                 </li>
               )
             })}
            
            </ul>
        </div>
        </Container>
    </Layout>
  )
}

export const Head = () => <Seo customCanonical="https://www.providentestate.com/sitemap/" title="Sitemap" />

export default Sitemap